<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReportingManagementSupervisor']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											Сведения об освоении квоты вылова рыбы и других водных животных пользователями
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="table__block--filter">
										<div class="table__filter--left">
											<div class="table__filter--search">
												<label class="search__label">
													<input
														type="text"
														value=""
														name="filter_search"
														v-model="filters.search"
														:placeholder="$t('placeholder.search')"
													/>
													<button>
														<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
													</button>
												</label>
											</div>
										</div>
										<div class="table__filter--right"></div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
									>
										<template v-slot:item.name="{ item }">
											<a class="tb__table--link"
												><!-- :href="'/'+ $i18n.locale +'/account/references/'+ item.id"  -->
												{{ item.name }}
											</a>
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.status == 'active',
													'tb__status--error': item.status == 'deleted',
												}"
											>
												{{ item.status == 'active' ? 'Активный' : '' }}
												{{ item.status == 'deleted' ? 'Заблокирован' : '' }}
												{{ item.status == 'new' ? 'Новый' : '' }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<router-link
													:to="urlApi + '/api/v1/generate/report/' + item.id + '?type_report=2'"
													class="tb__table--btn tb__table--list"
													v-tooltip.top-center="{
														content: $t('button.download'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.382 11.927v3a1.5 1.5 0 01-1.5 1.5h-10.5a1.5 1.5 0 01-1.5-1.5v-3M5.882 8.177l3.75 3.75 3.75-3.75M9.632 11.927v-9" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</router-link>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

export default {
	components: {},
	data() {
		return {
			urlApi: urlApi,

			applications: [],
			errorMessage: {
				status: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			Categories: 'all',
			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.report_name'), value: 'name', width: '40vw' },
				{ text: this.$t('headers.created_at'), value: 'created_at' },
				// { text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	methods: {
		apiGetReportingList() {
			api
				.get('reports', {
					params: {
						index: this.$route.params.itemId,
						user_id: this.$route.params.userId,
					},
				})
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return this.applications.filter((d) => {
				if (d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) {
					return true
				}
			})
		},
	},
	beforeMount() {
		this.apiGetReportingList()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
}
</script>
